// obtengo el hostname y lo puedo verificar
const hostname = window && window.location && window.location.hostname;

//let apiUri = 'https://pmgdspboxdev-backend.azurewebsites.net/api';
let apiUri = 'https://app-pmgd-qas-eus-01.azurewebsites.net/api';

const AccessTokenKeyMS365 = `SPBOX$H4R3P01NTK3YMSTR3$$31$$1NC0V20201224v2.${hostname}`;
const AccessTokenKeyGSuit = `SPBOX$H4R3P01NTK3YG5U1TV20201224v2.${hostname}`;
const AccessTokenKeyFusionAuth = `SPBOX$H4R3P01NTK3YG5U1TV20201224v2.${hostname}`;
const AccessTokenKey = `$PB0X$H4R3P01NT$3RV1C3V20201224v2.${hostname}`;
const UserStorageKey = `U$3R$T0R4G3K3Y$PB0XV20201224v2.${hostname}`;
const UserAccessEmail = `U$3R4CC3$$3M41L.${hostname}`;

// microsoft
let microsoft_idclient = '4ce684db-701c-41f9-974b-aa2c2fde077f';
const msalOptions = { scopes: ["openid", "profile", "user.read"] };
let baseUriDocuments = 'https://frontel.sharepoint.com';

// google
let google_idclient = '';

const configQAS = {
    apiUri,
    AccessTokenKeyMS365,
    AccessTokenKeyGSuit,
	AccessTokenKeyFusionAuth,
    AccessTokenKey,
    UserStorageKey,
    microsoft_idclient,
    microsoft_authority: 'https://login.microsoftonline.com/common',
    google_idclient,
    msalOptions,
    baseUriDocuments,
    UserAccessEmail
};

export default configQAS;
